import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EnvironmentalPolicy = () => (
  <Layout>
    <SEO title="Environmental Policy" />

    <div className="standalone-page container">    
        <h1>Environmental Policy</h1>
        <p>At Coldtap Australia we take seriously our responsibility to care for and protect the
    environment in which we operate. We are fully committed to improving environmental
    performance across all of our business activities, and will encourage our business
    partners and members of the wider community to share that commitment.</p>
        <p>We will:</p>
        <ul>
            <li>Adopt environmental standards in all areas of operation to ensure we meet all relevant legislative requirements;</li>
            <li>Having an environmentally sustainable aware culture, where responsibility is assigned and understood;</li>
            <li>Conserving natural resources by reusing and recycling;</li>
            <li>Minimise waste through efficient use of all materials;</li>
            <li>Reduce risks from environmental, health or safety hazards for employees and others in the vicinity of our operations;</li>
            <li>Ensuring the responsible use of energy throughout the organisation;</li>
            <li>Include environmental considerations in all investment decisions; and</li>
            <li>Continually assess the environmental impact of our operations.</li>
        </ul>
</div>


  </Layout>
)

export default EnvironmentalPolicy